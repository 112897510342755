import React, { createContext, useContext, useState } from "react";

const LinkedInContext = createContext();

export function LinkedinProvider({ children }) {
  const [isLinkedin, setIsLinkedin] = useState(false);

  return (
    <LinkedInContext.Provider value={{ isLinkedin, setIsLinkedin }}>
      {children}
    </LinkedInContext.Provider>
  );
}

export function useLinkedInContext() {
  return useContext(LinkedInContext);
}
