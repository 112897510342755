import axios from "axios";


const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL_V_TWO}`,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});
axiosInstance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers["Authorization"] = `Bearer${token}`;
    }
    return config;
  },
  function (error) {
    return;
    Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 401) {
        console.error("Unauthorized access. Redirecting to login.");
      }
    }
    return Promise.reject(error);
  }
);


export const deleteTrip = async (endPoint, request, headers) => {
  let obj = {};
  if (headers) {
    await axios
      .delete(endPoint, request, headers)
      .then((response) => {
        obj["status"] = "S";
        obj["response"] = response.data;
      })
      .catch((error) => {
        obj["status"] = "F";
        obj["errorData"] = error;
      });
    return obj;
  } else {
    await axios
      .delete(endPoint, request)
      .then((response) => {
        obj["status"] = "S";
        obj["response"] = response.data;
      })
      .catch((error) => {
        obj["status"] = "F";
        obj["errorData"] = error;
      });
    return obj;
  }
};

export const patchTripdata = async (endPoint, request, headers) => {
  let obj = {};
  if (headers) {
    await axios
      .patch(endPoint, request, headers)
      .then((response) => {
        obj["status"] = "S";
        obj["response"] = response.data;
      })
      .catch((error) => {
        obj["status"] = "F";
        obj["errorData"] = error;
      });
    return obj;
  } else {
    await axios
      .patch(endPoint, request)
      .then((response) => {
        obj["status"] = "S";
        obj["response"] = response.data;
      })
      .catch((error) => {
        obj["status"] = "F";
        obj["errorData"] = error;
      });
    return obj;
  }
};

export const postMethod = async (endPoint, request, headers) => {
  let obj = {};
  if (headers) {
    await axios
      .post(endPoint, request, headers)
      .then((response) => {
        obj["status"] = "S";
        obj["response"] = response.data;
      })
      .catch((error) => {
        obj["status"] = "F";
        obj["errorData"] = error;
      });
    return obj;
  } else {
    await axios
      .post(endPoint, request)
      .then((response) => {
        obj["status"] = "S";
        obj["response"] = response.data;
      })
      .catch((error) => {
        obj["status"] = "F";
        obj["errorData"] = error;
      });
    return obj;
  }
};

export const getMethod = async (endPoint, headers) => {
  let obj = {};
  if (headers) {
    await axios
      .get(endPoint, headers)
      .then((response) => {
        obj["status"] = "S";
        obj["response"] = response.data;
      })
      .catch((error) => {
        obj["status"] = "F";
        obj["errorData"] = error;
      });
    return obj;
  } else {
    await axios
      .get(endPoint)
      .then((response) => {
        obj["status"] = "S";
        obj["response"] = response.data;
      })
      .catch((error) => {
        obj["status"] = "F";
        obj["errorData"] = error;
      });
    return obj;
  }
};
