import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";

const StorageContext = createContext();

export function StorageProvider({ children }) {
  // Utility function to get and parse local storage values
  const getItem = (key) => {
    const value = localStorage.getItem(key);
    return value === "true" ? true : value === "false" ? false : value || "";
  };

  const [storageDetail, setStorageDetail] = useState({
    user_id: getItem("user_id"),
    chat_id: getItem("chat_id"),
    access_token: getItem("access_token"),
    refresh_token: getItem("refresh_token"),
    isLoggedIn: getItem("isL") === "true", // Convert to boolean
  });

  // Effect to sync state with local storage
  useEffect(() => {
    localStorage.setItem("user_id", storageDetail.user_id || "");
    localStorage.setItem("chat_id", storageDetail.chat_id || "");
    localStorage.setItem("access_token", storageDetail.access_token || "");
    localStorage.setItem("refresh_token", storageDetail.refresh_token || "");
    localStorage.setItem("isL", storageDetail.isLoggedIn ? "true" : "false");

    if (storageDetail.isLoggedIn && storageDetail.refresh_token) {
      const intervalId = setInterval(refreshTokenFunction, 2 * 60 * 1000); // 2 minutes
      return () => clearInterval(intervalId);
    }
  }, [storageDetail]);

  // Function to handle token refresh
  const refreshTokenFunction = () => {
    if (!storageDetail.refresh_token) return; // No refresh token available

    axios
      .post(`${process.env.REACT_APP_API_URL_V_TWO}v2/token/refresh/`, {
        refresh_token: storageDetail.refresh_token,
      })
      .then((res) => {
        const { access_token, refresh_token } = res.data;
        setStorageDetail((prev) => ({
          ...prev,
          access_token: access_token || prev.access_token, // Use new access token if available
          refresh_token: refresh_token || prev.refresh_token, // Update refresh token if available
        }));
      })
      .catch((err) => {
        console.error("Token refresh error:", err);
        // Handle error, e.g., log out the user or notify them
        setStorageDetail((prev) => ({
          ...prev,
          isLoggedIn: false, // Log out user if refresh fails
        }));
      });
  };

  // Utility function to set access token in headers for requests
  const setAuthHeader = (axiosInstance) => {
    axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${storageDetail.access_token}`;
    axiosInstance.defaults.headers.common["Content-Type"] = "application/json";
  };

  // Use this function to make authenticated API requests
  const makeAuthenticatedRequest = (url, options) => {
    const axiosInstance = axios.create();
    setAuthHeader(axiosInstance);

    return axiosInstance(url, options)
      .then((response) => {
        console.log(response, "makeRspose");
        // response;
        return {
          status: "S",
          response: response.data,
        };
      })

      .catch(async (error) => {
        if (error.response && error.response.status === 401) {
          // Token expired, try to refresh
          await refreshTokenFunction();
          setAuthHeader(axiosInstance); // Update header with new token
          return axiosInstance(url, options); // Retry request with new token
          
        } else {
          console.log(error, "makeError");
          return {
            status: "F",
            response: error,
          };
        }
        throw error; // Rethrow if not a token expiration issue
      });
  };

  return (
    <StorageContext.Provider
      value={{ storageDetail, setStorageDetail, makeAuthenticatedRequest }}
    >
      {children}
    </StorageContext.Provider>
  );
}

export function useStorageContext() {
  return useContext(StorageContext);
}
