import React from "react";
import { useLoadingContext } from "../BeforeLogin/Context/loadingContext";
import "../../YouePhase2/assets/CSS/loader.css"

const Loader = () => {
  const { isLoading } = useLoadingContext();
  return (
    isLoading && (
      <div className="stage">
        <div className="dot-spin"></div>
      </div>
    )
  );
};
export default Loader;
