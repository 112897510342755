import * as React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import avadar from "../../assets/icons/profile-colplete-avadar.svg";
import { Flex, Progress } from "antd";

const ProfileCompleteSpinner = () => {
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Progress
        type="circle"
        trailColor="var(--Subcolor, #DEDEDE)"
        strokeLinecap={"round"}
        size={70}
        strokeWidth={10}
        strokeColor={"var(--Brand-Color, #FF7228)"}
        format={(percent) => <img src={avadar} />}
      />
      <Box
        sx={{
          width: { md: "400px", sm: "400px", s: "400px" },
          height: "80px",
          borderRadius: "20px",
          border: " 1px solid var(--Brand-Color, #FF7228)",
          color: "var(--Brand-Color, #FF7228)",
          fontFamily: "Inter",
          fontSize: { md: "24px", sm: "24px", s: "20px" },
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "normal",
          display: "flex",
          padding: "20px",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          marginTop: "40px",
        }}
      >
        youe profile is{" "}
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "6px 10px",
            borderRadius: "15px",
            background: "var(--Highlight-Brand, rgba(255, 114, 40, 0.15))",
          }}
        >
          30%
        </span>{" "}
        complete
      </Box>
    </div>
  );
};

export default ProfileCompleteSpinner;
