import React, { createContext, useContext, useState,useEffect } from "react";

const StepContext = createContext();

export function StepProvider({ children }) {
  const [step, setStep] = useState(JSON.parse(localStorage.getItem("loadingPagein")));

  useEffect(() => {
    if (step){
      localStorage.setItem("loadingPagein", step);
    }
   
  }, [step]);


  return (
    <StepContext.Provider value={{ step, setStep }}>
      {children}
    </StepContext.Provider>
  );
}

export function useStepContext() {
  return useContext(StepContext);
}
