import React, { createContext, useContext, useState } from "react";

const profileSetupContext = createContext();

export function ProfileProvider({ children }) {
  const [profileData, setProfileData] = useState({});

  return (
    <profileSetupContext.Provider value={{ profileData, setProfileData }}>
      {children}
    </profileSetupContext.Provider>
  );
}

export function useProfileContext() {
  return useContext(profileSetupContext);
}
