import React, { createContext, useContext, useEffect, useState } from "react";

const LinkedInSignUpStateContext = createContext();

export function LinkedInSignUpStateProvider({ children }) {
  const [linkedInSignup, setLinkedInSignup] = useState("");



  return (
    <LinkedInSignUpStateContext.Provider
      value={{ linkedInSignup, setLinkedInSignup }}
    >
      {children}
    </LinkedInSignUpStateContext.Provider>
  );
}

export function useLinkedInSignUp() {
  return useContext(LinkedInSignUpStateContext);
}
